import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChange } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../../service/project.service';
import { CommonService } from '../../../../../service/common.service';
import { ModalDirective } from 'ngx-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PurchaseOrderService } from '../../../../../service/purchase-order.service';
import { RolepermissionService } from '../../../../../service/rolepermission.service';
import { Utils } from '../../../../../utils';
import { AccountingService } from '../../../../../service/accounting.service';
import Swal from 'sweetalert2';
import { SalesOrderService } from '../../../../../service/sales-order.service';
import { FranchiseService } from '../../../../../service/franchise.service';
import { AppConstants } from '../../../../../constants/app.constants';
import { ToasterService } from 'angular2-toaster';
import {AuthenticationService} from '../../../../../service/authentication.service';

@Component({
  selector: 'app-order-view',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss']
})

export class ViewOrderComponent implements OnInit, OnChanges {
  @ViewChild('videoModal') public videoModal: ModalDirective;
  @ViewChild('receiveModal') public receiveModal: ModalDirective;
  @ViewChild('stripePaymentModal') public stripePaymentModal: ModalDirective;
  @ViewChild('payModal') public payModal: ModalDirective;
  @ViewChild('billModal') public modal: ModalDirective;
  @Input() orderNo: any;
  franchiseId = '';
  currentUser:any = null;
  paidOrderData: [];
  invoiceOrderData: [];
  orderData: any = [];
  api_error = {};
  invoiceData: any = { subTotal: 0, tax: 0, shipping: 0, total: 0 };
  orderId = '';
  invoiceId = '';
  received_payment_id = null;
  paid_bill_payment_id = null;
  previousUrl: any = null;

  paymentOption:number = 3; // 3 default for both options(Bank Transfer & Card)
  id: '';
  oneAtATime: Boolean = true;
  poData: any = [];
  canCreate = false;
  summaryData: any = [];
  status: any = {
    loading: false,
    success: false,
    error: false
  };
  proofStatus :any= {
    loading: false,
    success: false,
    error: false
  }
  franchiseData: any = [];
  franchiseLogo = '';
  loading = false;
  isSalesRep = false;
  isDigitalProject = false;
  hideAction = false;

  pieChartType = 'pie';
  invoiceSubtotal = 0;

  chartColors1 = [
    {
      backgroundColor: [
        'rgb(0, 153, 51)',
        'rgb(51, 153, 255)'
      ]
    }
  ];

  chartColors2 = [
    {
      backgroundColor: [
        'rgb(255, 92, 51)',
        'rgb(102, 0, 255)'
      ]
    }
  ];
  suggestedArr: any = [];
  sugdocArr: any = [];
  sugoldDocArr: any = [];
  prrofForm: FormGroup;
  // for receivables
  labels1: string[] = ['Received Amount', 'Due Amount'];
  receiveData: number[] = [100, 0];
  chartType1 = 'pie';

  // for payables
  labels2: string[] = ['Paid Amount', 'Due Amount'];
  payData: number[] = [100, 0];
  isAccountant: boolean = false;
  isAdmin: boolean = false;
  isEstimator: boolean = false;
  isCSR = false;
  poCreatedCheck: boolean = false;
  qbInvoice: any = []
  currentItemProof:any = []
  approveRejectReason: any = '';
  showErrorMsg:any = false;
  customerInformation = {};
  singleBill: any = [];
  hideDetial: boolean = false;
  constructor(private router: Router,
    private projectService: ProjectService,
    private permissionService: RolepermissionService,
    private utils: Utils,
    private orderService: SalesOrderService,
    private activatedRoute: ActivatedRoute,
    private poService: PurchaseOrderService,
    public commonService: CommonService,
    private _accountingService: AccountingService,
    private franchiseService: FranchiseService,
    private _fb:FormBuilder,
    private toasterService: ToasterService,
    private authService: AuthenticationService,
    ) {
  }

  ngOnInit() {
    this.commonService.previousUrl$
    .subscribe((previousUrl: string) => {
        this.previousUrl = previousUrl
    });
    
    this.authService.userData.subscribe((res) => {
      this.currentUser = res['id'];
    });

    this.franchiseId = this.commonService.currentFranchise();
    this.franchiseData = this.franchiseService.franchiseData.getValue();

    const configStatus = this.commonService.configStatus.getValue();
    if (Object.keys(configStatus).length === 0) {
      this.commonService.getConfigStatusData().subscribe(res => {
        this.commonService.configStatus.next(res);
      });
    }

    const logo = this.commonService.UPLOAD_FILE_AWS('franchise_images');
    this.franchiseLogo = logo + (this.franchiseData['print_logo'] ? this.franchiseData['print_logo'] : this.franchiseData['logo']);

    if (this.franchiseData['role']['original_name'] === AppConstants['roles']['admin']) {
      this.isAdmin = true
    } else if (this.franchiseData['role']['original_name'] === AppConstants['roles']['accountant']) {
      this.isAccountant = true
    } else if (this.franchiseData['role']['original_name'] === AppConstants['roles']['estimator']) {
      this.isEstimator = true
    } else if (this.franchiseData['role']['original_name'] === AppConstants['roles']['estimator_hod']) {
      this.isCSR = true
    }

    if (this.franchiseData['role']['original_name'] === AppConstants['roles']['sales_rep']) {
      this.isSalesRep = true;
      this.commonService.introType.next('sales_order_repo_view');
    } else {
      this.commonService.introType.next('sales_order_view');
    }
    if (this.orderNo) {
    } else {
      this.activatedRoute.params.subscribe(resId => {
        if (resId['id']) {
          this.id = resId['id'];
          this.orderId = resId['id'];
        } else {
          this.orderId = resId['order_id'];
        }
        this.getOrderData();
      });
    }
    this.initProofForm();
    this.checkPermission();
  }

  checkPermission = () =>{
    this.hideDetial = (this.franchiseId == '6' || this.franchiseId == '17');
  }

  openProofModal = (item) => {
    this.proofStatus = this.utils.showContainers(this.proofStatus, 'success');
    if(this.orderData['proof_status'] != 3){
    this.suggestedArr=[]
    }
    this.currentItemProof = item
    this.prrofForm.get('item_id').setValue(this.currentItemProof.id)
    this.videoModal.show()
  }

  // canOrderBeClosed = () => {
  //   return (this.orderData['purchase_closed'] == 0 || !this.orderData['purchase_closed'])
  //     && (this.orderData['order_po_numbers'] != 0 && this.orderData['order_bills_numbers'] != 0)
  //     && (this.orderData['order_po_numbers'] == this.orderData['order_bills_numbers'])
  //     && this.orderData['order_invoice']
  //     && this.orderData['payment_status'] == 2
  //     && this.orderData['status'] == 7;
  // }
  
  canOrderBeClosed = () => {
    const errors = [];
  
    if (this.orderData['purchase_closed'] != 0 && this.orderData['purchase_closed']) {
      errors.push('The purchase is already closed.');
    }
    if (this.orderData['order_po_numbers'] == 0 || this.orderData['order_bills_numbers'] == 0) {
      errors.push('PO or bill are not generated.');
    }
    if (this.orderData['order_po_numbers'] != this.orderData['order_bills_numbers']) {
      errors.push('PO numbers and bill numbers do not match.');
    }
    if (!this.orderData['order_invoice']) {
      errors.push('Invoice is not generated.');
    }
    if (this.orderData['payment_status'] != 2) {
      errors.push('Payment status is not completed.');
    }
    if (this.orderData['status'] != 7) {
      errors.push('Order status is not shipped.');
    }
  
    return errors;
  };
  

  suggestedRemoveDoc = (doc, i) => {
    this.suggestedArr.splice(i, 1);
    this.sugdocArr.forEach((value, key) => {
      if (key === +i) {
        this.sugdocArr.splice(key, 1);
      }
    });
    this.sugoldDocArr.forEach((value, key) => {
      if (key === +i) {
        this.sugoldDocArr.splice(key, 1);
      }
    });
  };

  suggestedUploadDoc = (event: any) => {
    const files = event.target.files;
    let i = this.suggestedArr.length;
    for (const file of files) {
      const ext = file['name'].substring(file['name'].lastIndexOf('.') + 1).toLowerCase();
      let flag = true;
      this.sugdocArr.forEach((value) => {
        if (value === file.name) {
          flag = false;
        }
      });
      if (flag) {
        this.sugdocArr.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event1: Event) => {
          this.suggestedArr[i] = {};
          this.suggestedArr[i]['file'] = file;
          const pathValue = this.commonService.getLogoUrl(ext);
          if (!pathValue) {
            this.suggestedArr[i]['url'] = event1.target['result'];
          } else {
            this.suggestedArr[i]['url'] = pathValue;
          }
          i++;
        };
      }
    }
  }

  closeProofModal = () => {
    this.videoModal.hide()
  }

  initProofForm = () => {
    this.prrofForm = this._fb.group({
      proof_artwork:[''],
      proof_description:[''],
      order_id:[this.orderId],
      id:[this.orderId],
      item_id:[this.currentItemProof ? this.currentItemProof['id'] : ''],
    });
  }
  
  saveProof = () => {
    this.proofStatus = this.utils.showContainers(this.proofStatus, 'loading');
    let postData = []
    postData = {...this.prrofForm.value};
    delete postData['suggestedartwork'];
    
    postData['old_proof_artwork'] = this.sugoldDocArr;
    const fData: FormData = new FormData;
    if (this.suggestedArr.length > 0) {
      for (const file of this.suggestedArr) {
        if (file['file']) {
          fData.append('proof_artwork[]', file['file']);
        }
      }
    }
    Object.keys(postData).forEach(field => {
      if(field != 'proof_artwork'){
        fData.append(field, postData[field]);
      }
    })

    this.orderService.uploadProof(fData).subscribe((res) => {
    this.proofStatus = this.utils.showContainers(this.proofStatus, 'success');
      this.videoModal.hide();
      this.ngOnInit();
    },(error) => {
      this.proofStatus = this.utils.showContainers(this.proofStatus, 'success');
      if (error['error']) {
        this.loading = false;
        this.api_error = error['error']['data'];
      }
    })
  }

  returnStringWithBold = (description) => {
    if(this.orderData && this.orderData['project_of_order']){
      if(this.orderData['project_of_order']['project_type'] == 8){
      let string =description
      let final = []
      var c = string.split(", ");
      c.map((item) => {
        var char = item.split(":");
        if (char[0] && char[1]) {
          final.push(char[0].bold() +' : '+ char[1] +'<br>')
        }
      });
      return final.join(' ')
  }else{
    return description
  }
  }else{
    return description
  }
}

confirmInvoiceEmailSend = (alreadySend) => {
  if(alreadySend){
    Swal.fire({
      title: 'Are you sure?',
      text: 'Email already sent! Do you want to send it again?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
      },
    }).then((res) => {
      if(res['value']){
      Swal.fire({
        title: "This description will send to your customer in email!",
        html: "<textarea id='text' placeholder='' class='form-control' [(ngModel)]='approveRejectReason'></textarea>",
        // --------------^-- define html element with id
        // html: "true",
        showCancelButton: true,
        confirmButtonText: 'Send',
        showLoaderOnConfirm: true,
        inputPlaceholder: "Write something",
        inputValidator: (value) => {
          return new Promise((resolve) => {
            resolve('')
          });
        },
        preConfirm: (value1) => {
          var val = document.getElementById('text')["value"];
            var postData = {}
            postData['invoice_id'] = this.invoiceId
            postData['order_id'] = this.orderId
            postData['description'] = val;
            postData['isEmailSend'] = 1;
              return this.orderService.sendInvoiceEmail(postData).toPromise()
                .then((res) => {
                  if (res['data']) {
                    return true;
                  }
                })
                .catch(() => {
                  return true;
                });
        }
      }).then((res) => {
        if (res['value']) {
          this.ngOnInit()
        }
      });
    }
    });
  }else{
  Swal.fire({
    title: "This description will send to your customer in email!",
    html: "<textarea id='text' placeholder='' class='form-control' [(ngModel)]='approveRejectReason'></textarea>",
    // --------------^-- define html element with id
    // html: "true",
    showCancelButton: true,
    confirmButtonText: 'Send',
    showLoaderOnConfirm: true,
    inputPlaceholder: "Write something",
    inputValidator: (value) => {
      return new Promise((resolve) => {
        resolve('')
      });
    },
    preConfirm: (value1) => {
      var val = document.getElementById('text')["value"];
        var postData = {}
        postData['invoice_id'] = this.invoiceId
        postData['order_id'] = this.orderId
        postData['description'] = val
          return this.orderService.sendInvoiceEmail(postData).toPromise()
            .then((res) => {
              if (res['data']) {
                return true;
              }
            })
            .catch(() => {
              return true;
            });
      }
    }).then((res) => {
      if (res['value']) {
        this.ngOnInit()
      }
    });
  }
}

confirmEmailSend = (id,alreadySend) => {
  if(alreadySend){
    Swal.fire({
      title: 'Are you sure?',
      text: 'Email already sent! Do you want to send it again?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
      },
    }).then((res) => {
      if(res['value']){
        Swal.fire({
          title: "This description will send to your customer in email!",
          html: "<textarea id='text' placeholder='' class='form-control' [(ngModel)]='approveRejectReason'></textarea>",
          // --------------^-- define html element with id
          // html: "true",
          showCancelButton: true,
          confirmButtonText: 'Send',
          showLoaderOnConfirm: true,
          inputPlaceholder: "Write something",
          inputValidator: (value) => {
            return new Promise((resolve) => {
              resolve('')
            });
          },
          preConfirm: (value1) => {
            var val = document.getElementById('text')["value"];
              var postData = {}
              postData['id'] = this.orderId
              postData['description'] = val;
                return this.orderService.sendOrderEmail(postData).toPromise()
                  .then((res) => {
                    if (res['data']) {
                      return true;
                    }
                  })
                  .catch(() => {
                    return true;
                  });
          }
        }).then((res) => {
          if (res['value']) {
            this.ngOnInit()
          }
        });
      }
    });
  }else{
    Swal.fire({
      title: "This description will send to your customer in email!",
      html: "<textarea id='text' placeholder='' class='form-control' [(ngModel)]='approveRejectReason'></textarea>",
      // --------------^-- define html element with id
      // html: "true",
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      inputPlaceholder: "Write something",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          resolve('');
        });
      },
      preConfirm: (value1) => {
        var val = document.getElementById('text')["value"];
          var postData = {}
          postData['id'] = this.orderId
          postData['description'] = val;
            return this.orderService.sendOrderEmail(postData).toPromise()
              .then((res) => {
                if (res['data']) {
                  return true;
                }
              })
              .catch(() => {
                return true;
              });
      }
    }).then((res) => {
      if (res['value']) {
        this.ngOnInit()
      }
    });
  }
}
  AddPoNumber = (id, number) => {
    Swal.fire({
      title: 'Add Customer PO Number',
      input: 'text',
      showLoaderOnConfirm: true,
      customClass: {
        input: 'status-select'
      },
      inputPlaceholder: 'Customer PO Number',
      inputValue: number,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      preConfirm: (value) => {
        const data = {};
        data['id'] = id;
        data['customer_po_number'] = value;
        if (data) {
          return this.orderService.updatePONumber(data).toPromise()
            .then((res) => {
              if (res['data']) {
                this.updateOrderData(res['data']['customer_po_number'], id)
              }
            })
            .catch(() => {
              return true;
            });
        }
      },
    }).then((res) => {
    });
  }
  updateOrderData = (po_number, id) => {
    if (Object.keys(this.orderData).length) {
      Object.keys(this.orderData).map((item, index) => {
        if (item == 'customer_po_number') {
          this.orderData[item] = po_number;
        }
      })
    }
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    const change: SimpleChange = changes['orderNo'];
    this.orderNo = change['currentValue'];
    this.id = this.orderNo;
    this.orderId = this.orderNo;
    this.getOrderData();
  }

  getOrderData = () => {
    this.status = this.utils.showContainers(this.status, 'loading');
    const permission = this.permissionService.hasAnyPermissions('view_purchase_order,view_purchase_orders_accountant');
    this.projectService.getOrderDetailData(this.orderId).subscribe(res => {
      if (this.isSalesRep && this.currentUser != res['data']['orderData']['project_of_order']['sales_rep_id']) {
        this.hideAction = true;
      } else if (this.isCSR && this.currentUser != res['data']['orderData']['project_of_order']['csr_user_id']) {
        this.hideAction = true;
      }
      
      this.paidOrderData = res['data']['paid'];
      this.invoiceOrderData = res['data']['received'];
      if (permission) {
        this.getPoData();
      }
      this.orderData = res['data']['orderData'];
      this.orderData['gross_profit_percent'] = (((this.orderData['gross_profit'] || 0) * 100) / (this.orderData['sale_amt'] || 1)).toFixed(2);
      this.projectService.getCustomerInvoiceData(this.orderData['contact_of_order']['id']).subscribe((clientInvoices) => {
        if (clientInvoices) {
          this.customerInformation = clientInvoices;
        }
      });
      if(this.orderData['proof_status'] == 3){
      this.sugdocArr = (this.orderData['proof_artwork']) ? this.orderData['proof_artwork'] : [];
      this.sugoldDocArr = this.orderData['proof_artwork'] ?[...this.orderData['proof_artwork']] : [] 
      this.prrofForm.get('proof_description').setValue(this.orderData['proof_description']?this.orderData['proof_description']:'')
      if(this.sugdocArr){
        this.sugdocArr.forEach((value, key) => {
          this.suggestedArr[key] = [];
          const pathValue = this.commonService.checkImageTypeAws(value, 'sales_order_images');
          if (!pathValue) {
            this.suggestedArr[key]['url'] = this.commonService.UPLOAD_FILE_AWS('sales_order_images') + value;
          } else {
            this.suggestedArr[key]['url'] = pathValue;
          }
        });
      }
    }
      const received = res['data']['received'];
      if (res['data']['qb_orderData']) {
        this.qbInvoice = JSON.parse(JSON.stringify(res['data']['qb_orderData']['Invoice'] || []));
      }
      const paid = res['data']['paid'];
      this.invoiceId = (res['data']['orderData']['order_invoice']) ? res['data']['orderData']['order_invoice']['id'] : null;
      this.paymentOption = (res['data']['orderData']['payment_option'] ? res['data']['orderData']['payment_option'] : 3) ;
      const invoiceitems = JSON.parse(JSON.stringify(this.orderData['order_invoice_items']));
      for (const item of invoiceitems) {
        this.invoiceSubtotal += +item['price'];
      }
      this.summaryData = {
        'received': received,
        'allReceived': res['data']['all_recieved'],
        'allPaid': res['data']['all_paid'],
        'paid': paid
      };
      this.receiveData = [received['received'], received['due']];
      this.payData = [paid['paid'], paid['due']];

      this.status = this.utils.showContainers(this.status, 'success');

      if (this.orderData['project_of_order']) {
        if (this.orderData['project_of_order']['project_spec']) {
          this.orderData['project_of_order']['project_spec'] = JSON.parse(this.orderData['project_of_order']['project_spec']);
        }
      }

      const tempOrderData = JSON.parse(JSON.stringify(this.orderData['order_items']));

      let poCreatedCount = 0;
      for (const data of tempOrderData) {
        if (data['po_created']) {
          poCreatedCount++;
        }
        data['price'] = Math.round(data['price']);
        data['tax'] = Math.round((data['tax'] * data['price']) / 100);
        data['shipping_cost'] = Math.round(data['shipping_cost']);
        data['total'] = Math.round((data['price'] + data['tax'] + data['shipping_cost']));
        this.invoiceData['subTotal'] += data['price'];
        this.invoiceData['tax'] += data['tax'];
        this.invoiceData['shipping'] += data['shipping_cost'];
        this.invoiceData['total'] += data['total'];
      }
      if (poCreatedCount != 0 && poCreatedCount == this.orderData['order_items'].length) {
        this.poCreatedCheck = true
      }
      if (this.orderData['project_of_order']) {
        if (this.orderData['project_of_order']['project_type'] == 4) {
          this.isDigitalProject = true
        }
      }
    },(error) => {
      if(error['error']['data'] == 'invalid_request') {
        this.toasterService.pop('error', error['error']['message']);
        this.router.navigate(['../../../dashboard'], { relativeTo: this.activatedRoute });
      }
    });
  }

  getPoData = () => {
    this.poService.getPoData(this.orderId).subscribe(res => {
      if (res['data']) {
        this.poData = res['data'];
        this.getPoCount();
      }
    });
  }


  getPoCount = () => {
    let totalItemsCost = 0;
    let totalPoCost = 0;
    this.orderData['order_items'].map((item) => {
      totalItemsCost += item['total_cost'];
    });

    this.poData.map((item) => {
      totalPoCost += +item['our_cost'];
    });

    if (totalPoCost < totalItemsCost) {
      this.canCreate = true;
    }
  }

  back = () => {
    this.router.navigateByUrl(this.previousUrl || '/'+this.franchiseId+'/sales-order');
  }

  openpo = () => {
    if (this.orderData['purchase_closed']) {
      this.reOpenPo();
    } else {
      if (this.orderNo) {
        this.router.navigate(['../../purchase-order/create-po/' + this.orderId], { relativeTo: this.activatedRoute });
      } else {
        if (this.id) {
          this.router.navigate(['../../../../purchase-order/create-po/' + this.orderId], { relativeTo: this.activatedRoute });
        } else {
          this.router.navigate(['../../../purchase-order/create-po/' + this.orderId], { relativeTo: this.activatedRoute });
        }
      }
    }
  }

  reOpenPo = () => {
    Swal.fire({
      title: 'Purchase Closed Do you want to reopen?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reopen Purchase!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.orderService.reopenPurchaseOrder(+this.orderId).toPromise()
          .then((res) => {
            if (res['data']) {
              return true;
            }
          })
          .catch(() => {
            return true;
          });
      },
    }).then((res) => {
      if (res['value']) {
        this.orderData['purchase_closed'] = 0;
      }
    });
  }

  // closePurchase = () => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You won\'t be able to revert this!',
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, Close Purchase!',
  //     showLoaderOnConfirm: true,
  //     preConfirm: () => {
  //       return this.orderService.closePurchaseOrder(+this.orderId).toPromise()
  //         .then((res) => {
  //           window['storedRoutes'] = {};
  //           if (res['data']) {
  //             return true;
  //           }
  //         })
  //         .catch(() => {
  //           return false;
  //         });
  //     },
  //   }).then((res) => {
  //     if (res['value']) {
  //       this.orderData['purchase_closed'] = 1;
  //     }

  //   });
  // }

  closePurchase = () => {
    const errors = this.canOrderBeClosed();
  
    if (errors.length > 0) {
      Swal.fire({
        title: 'Error',
        html: `<ul style="text-align: left;">${errors.map(error => `<li>${error}</li>`).join('')}</ul>`,
        type: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }
  
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Close Purchase!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.orderService.closePurchaseOrder(+this.orderId).toPromise()
          .then((res) => {
            window['storedRoutes'] = {};
            if (res['data']) {
              return true;
            }
          })
          .catch(() => {
            return false;
          });
      },
    }).then((res) => {
      if (res['value']) {
        this.orderData['purchase_closed'] = 1;
      }
    });
  }
  

  getCommaSeparetedItems = (items1) => {
    const nameArray = [];
    items1.map(product => {
      nameArray.push(product['item_spec']);
    });
    return nameArray.join(', ');
  }

  onOrderSelect = (event) => {
  }

  openReceiveForm = () => {
    this.receiveModal.show();
  }

  openStripePaymentForm = () => {
    this.stripePaymentModal.show();
  }

  openPayForm = () => {
    this.payModal.show();
  }

  getAllReceived = () => {
    this._accountingService.getAllReceived(this.orderId).subscribe(
      (res) => {
        if (res['data']) {
          this.receiveData = [res['data']['received']['received'], res['data']['received']['due']];
          this.summaryData['received'] = res['data']['received'];
          this.summaryData['allReceived'] = res['data']['all_recieved'];
        }
      }
    );
  }

  getAllPaid = () => {
    this._accountingService.getAllPaid(this.orderId).subscribe(
      (res) => {
        if (res['data']) {
          this.payData = [res['data']['paid']['paid'], res['data']['paid']['due']];
          this.summaryData['allPaid'] = res['data']['all_paid'];
          this.summaryData['paid'] = res['data']['paid'];
        }
      }
    );
  }

  validateAndDownload = () => {
    this.orderData['franchise_logo'] = this.franchiseLogo;
    this.loading = true;
    this.projectService.downloadSalesOrderPdf(this.orderData).subscribe(
      (res: any) => {
        this.api_error = {};
        this.loading = false;
        const blobData = new Blob([res], { type: 'application/octet-stream' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blobData);
        a.download = this.orderData['sales_order_uid'] + '_sales_order.pdf';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        this.loading = false;
        if (error['error']['data']) {
          this.api_error = error['error']['data'];
        }
      }
    );
  }

  confirmApprove = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve it!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.projectService.approveSalesOrder(id, 1).toPromise()
          .then((res) => {
            if (res['data']) {
              return true;
            }
          })
          .catch(() => {
            return true;
          });
      },
    }).then((res) => {
      if (res['value']) {
        this.ngOnInit();
      }
    });
  }

  viewBill = (id) => {
    this.singleBill = [];
    this.poService.getBillDetails(id).subscribe(res => {
      this.modal.show();
      this.singleBill = res['data'];
    });
  }

  deleteBill = (id) => {
    Swal.fire({
      title: "Are you sure to delete this bill?",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      preConfirm: (value) => {
        return this.poService.deleteBill(id).toPromise().then((res) => {
          if (res['data']) {
            return true;
          }
        }).catch(() => {
          return true;
        });
      }
    }).then((res) => {
      if (res['value']) {
        this.getOrderData();
        // this.rerender()
      }
    });
  }

  deletePaidPayment = (id) => {
    Swal.fire({
      title: "Are you sure to delete the paid bill payment?",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      preConfirm: (value) => {
        return this._accountingService.deletePaidBillPayment(id).toPromise().then((res) => {
          if (res['data']) {
            this.getAllPaid();
            return true;
          }
        }).catch(() => {
          return true;
        });
      }
    }).then((res) => {
      // Do nothing
    });
  }

  deletePO = (id) => {
    Swal.fire({
      title: "Are you sure to delete this purchase order?",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      preConfirm: (value) => {
        return this.poService.deletePO(id).toPromise().then((res) => {
          if (res['data']) {
            this.getOrderData();
            return true;
          }
        }).catch((error) => {
          this.toasterService.pop('error', error['error']['message']);
        });
      }
    });
  }

  deleteReceivedPayment = (id) => {
    Swal.fire({
      title: "Are you sure to delete the received payment?",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      preConfirm: (value) => {
        return this._accountingService.deleteReceivedPayment(id).toPromise().then((res) => {
          if (res['data']) {
            this.getAllReceived();
            return true;
          }
        }).catch(() => {
          return true;
        });
      }
    }).then((res) => {
      // Do nothing
    });
  }
}